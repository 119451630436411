:root {
  --dt-row-selected: 13, 110, 253;
  --dt-row-selected-text: 255, 255, 255;
  --dt-row-selected-link: 9, 10, 11;
  --dt-row-stripe: 0, 0, 0;
  --dt-row-hover: 0, 0, 0;
  --dt-column-ordering: 0, 0, 0;
  --dt-html-background: white;
}

:root.dark {
  --dt-html-background: #212529;
}

table.dataTable td.dt-control {
  text-align: center;
  cursor: pointer;
}

table.dataTable td.dt-control:before {
  color: #00000080;
  content: "▶";
  display: inline-block;
}

table.dataTable tr.dt-hasChild td.dt-control:before {
  content: "▼";
}

html.dark table.dataTable td.dt-control:before, html.dark table.dataTable tr.dt-hasChild td.dt-control:before {
  color: #ffffff80;
}

table.dataTable thead > tr > th.sorting, table.dataTable thead > tr > th.sorting_asc, table.dataTable thead > tr > th.sorting_desc, table.dataTable thead > tr > th.sorting_asc_disabled, table.dataTable thead > tr > th.sorting_desc_disabled, table.dataTable thead > tr > td.sorting, table.dataTable thead > tr > td.sorting_asc, table.dataTable thead > tr > td.sorting_desc, table.dataTable thead > tr > td.sorting_asc_disabled, table.dataTable thead > tr > td.sorting_desc_disabled {
  cursor: pointer;
  padding-right: 26px;
  position: relative;
}

table.dataTable thead > tr > th.sorting:before, table.dataTable thead > tr > th.sorting:after, table.dataTable thead > tr > th.sorting_asc:before, table.dataTable thead > tr > th.sorting_asc:after, table.dataTable thead > tr > th.sorting_desc:before, table.dataTable thead > tr > th.sorting_desc:after, table.dataTable thead > tr > th.sorting_asc_disabled:before, table.dataTable thead > tr > th.sorting_asc_disabled:after, table.dataTable thead > tr > th.sorting_desc_disabled:before, table.dataTable thead > tr > th.sorting_desc_disabled:after, table.dataTable thead > tr > td.sorting:before, table.dataTable thead > tr > td.sorting:after, table.dataTable thead > tr > td.sorting_asc:before, table.dataTable thead > tr > td.sorting_asc:after, table.dataTable thead > tr > td.sorting_desc:before, table.dataTable thead > tr > td.sorting_desc:after, table.dataTable thead > tr > td.sorting_asc_disabled:before, table.dataTable thead > tr > td.sorting_asc_disabled:after, table.dataTable thead > tr > td.sorting_desc_disabled:before, table.dataTable thead > tr > td.sorting_desc_disabled:after {
  opacity: .125;
  font-size: .8em;
  line-height: 9px;
  display: block;
  position: absolute;
  right: 10px;
}

table.dataTable thead > tr > th.sorting:before, table.dataTable thead > tr > th.sorting_asc:before, table.dataTable thead > tr > th.sorting_desc:before, table.dataTable thead > tr > th.sorting_asc_disabled:before, table.dataTable thead > tr > th.sorting_desc_disabled:before, table.dataTable thead > tr > td.sorting:before, table.dataTable thead > tr > td.sorting_asc:before, table.dataTable thead > tr > td.sorting_desc:before, table.dataTable thead > tr > td.sorting_asc_disabled:before, table.dataTable thead > tr > td.sorting_desc_disabled:before {
  content: "▲";
  content: "▲" / "";
  bottom: 50%;
}

table.dataTable thead > tr > th.sorting:after, table.dataTable thead > tr > th.sorting_asc:after, table.dataTable thead > tr > th.sorting_desc:after, table.dataTable thead > tr > th.sorting_asc_disabled:after, table.dataTable thead > tr > th.sorting_desc_disabled:after, table.dataTable thead > tr > td.sorting:after, table.dataTable thead > tr > td.sorting_asc:after, table.dataTable thead > tr > td.sorting_desc:after, table.dataTable thead > tr > td.sorting_asc_disabled:after, table.dataTable thead > tr > td.sorting_desc_disabled:after {
  content: "▼";
  content: "▼" / "";
  top: 50%;
}

table.dataTable thead > tr > th.sorting_asc:before, table.dataTable thead > tr > th.sorting_desc:after, table.dataTable thead > tr > td.sorting_asc:before, table.dataTable thead > tr > td.sorting_desc:after {
  opacity: .6;
}

table.dataTable thead > tr > th.sorting_desc_disabled:after, table.dataTable thead > tr > th.sorting_asc_disabled:before, table.dataTable thead > tr > td.sorting_desc_disabled:after, table.dataTable thead > tr > td.sorting_asc_disabled:before {
  display: none;
}

table.dataTable thead > tr > th:active, table.dataTable thead > tr > td:active {
  outline: none;
}

div.dataTables_scrollBody > table.dataTable > thead > tr > th:before, div.dataTables_scrollBody > table.dataTable > thead > tr > th:after, div.dataTables_scrollBody > table.dataTable > thead > tr > td:before, div.dataTables_scrollBody > table.dataTable > thead > tr > td:after {
  display: none;
}

div.dataTables_processing {
  text-align: center;
  z-index: 10;
  width: 200px;
  margin-top: -26px;
  margin-left: -100px;
  padding: 2px;
  position: absolute;
  top: 50%;
  left: 50%;
}

div.dataTables_processing > div:last-child {
  width: 80px;
  height: 15px;
  margin: 1em auto;
  position: relative;
}

div.dataTables_processing > div:last-child > div {
  background: #0d6efd;
  background: rgb(var(--dt-row-selected));
  border-radius: 50%;
  width: 13px;
  height: 13px;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
  position: absolute;
  top: 0;
}

div.dataTables_processing > div:last-child > div:first-child {
  animation: .6s infinite datatables-loader-1;
  left: 8px;
}

div.dataTables_processing > div:last-child > div:nth-child(2) {
  animation: .6s infinite datatables-loader-2;
  left: 8px;
}

div.dataTables_processing > div:last-child > div:nth-child(3) {
  animation: .6s infinite datatables-loader-2;
  left: 32px;
}

div.dataTables_processing > div:last-child > div:nth-child(4) {
  animation: .6s infinite datatables-loader-3;
  left: 56px;
}

@keyframes datatables-loader-1 {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes datatables-loader-3 {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(0);
  }
}

@keyframes datatables-loader-2 {
  0% {
    transform: translate(0);
  }

  100% {
    transform: translate(24px);
  }
}

table.dataTable.nowrap th, table.dataTable.nowrap td {
  white-space: nowrap;
}

table.dataTable th.dt-left, table.dataTable td.dt-left {
  text-align: left;
}

table.dataTable th.dt-center, table.dataTable td.dt-center, table.dataTable td.dataTables_empty {
  text-align: center;
}

table.dataTable th.dt-right, table.dataTable td.dt-right {
  text-align: right;
}

table.dataTable th.dt-justify, table.dataTable td.dt-justify {
  text-align: justify;
}

table.dataTable th.dt-nowrap, table.dataTable td.dt-nowrap {
  white-space: nowrap;
}

table.dataTable thead th, table.dataTable thead td, table.dataTable tfoot th, table.dataTable tfoot td, table.dataTable thead th.dt-head-left, table.dataTable thead td.dt-head-left, table.dataTable tfoot th.dt-head-left, table.dataTable tfoot td.dt-head-left {
  text-align: left;
}

table.dataTable thead th.dt-head-center, table.dataTable thead td.dt-head-center, table.dataTable tfoot th.dt-head-center, table.dataTable tfoot td.dt-head-center {
  text-align: center;
}

table.dataTable thead th.dt-head-right, table.dataTable thead td.dt-head-right, table.dataTable tfoot th.dt-head-right, table.dataTable tfoot td.dt-head-right {
  text-align: right;
}

table.dataTable thead th.dt-head-justify, table.dataTable thead td.dt-head-justify, table.dataTable tfoot th.dt-head-justify, table.dataTable tfoot td.dt-head-justify {
  text-align: justify;
}

table.dataTable thead th.dt-head-nowrap, table.dataTable thead td.dt-head-nowrap, table.dataTable tfoot th.dt-head-nowrap, table.dataTable tfoot td.dt-head-nowrap {
  white-space: nowrap;
}

table.dataTable tbody th.dt-body-left, table.dataTable tbody td.dt-body-left {
  text-align: left;
}

table.dataTable tbody th.dt-body-center, table.dataTable tbody td.dt-body-center {
  text-align: center;
}

table.dataTable tbody th.dt-body-right, table.dataTable tbody td.dt-body-right {
  text-align: right;
}

table.dataTable tbody th.dt-body-justify, table.dataTable tbody td.dt-body-justify {
  text-align: justify;
}

table.dataTable tbody th.dt-body-nowrap, table.dataTable tbody td.dt-body-nowrap {
  white-space: nowrap;
}

table.dataTable {
  clear: both;
  border-collapse: separate;
  border-spacing: 0;
  width: 100%;
  margin: 0 auto;
}

table.dataTable thead th, table.dataTable tfoot th {
  font-weight: bold;
}

table.dataTable > thead > tr > th, table.dataTable > thead > tr > td {
  border-bottom: 1px solid #0000004d;
  padding: 10px;
}

table.dataTable > thead > tr > th:active, table.dataTable > thead > tr > td:active {
  outline: none;
}

table.dataTable > tfoot > tr > th, table.dataTable > tfoot > tr > td {
  border-top: 1px solid #0000004d;
  padding: 10px 10px 6px;
}

table.dataTable tbody tr {
  background-color: #0000;
}

table.dataTable tbody tr.selected > * {
  box-shadow: inset 0 0 0 9999px #0d6efde6;
  box-shadow: inset 0 0 0 9999px rgba(var(--dt-row-selected), .9);
  color: rgb(var(--dt-row-selected-text));
}

table.dataTable tbody tr.selected a {
  color: rgb(var(--dt-row-selected-link));
}

table.dataTable tbody th, table.dataTable tbody td {
  padding: 8px 10px;
}

table.dataTable.row-border > tbody > tr > th, table.dataTable.row-border > tbody > tr > td, table.dataTable.display > tbody > tr > th, table.dataTable.display > tbody > tr > td {
  border-top: 1px solid #00000026;
}

table.dataTable.row-border > tbody > tr:first-child > th, table.dataTable.row-border > tbody > tr:first-child > td, table.dataTable.display > tbody > tr:first-child > th, table.dataTable.display > tbody > tr:first-child > td {
  border-top: none;
}

table.dataTable.row-border > tbody > tr.selected + tr.selected > td, table.dataTable.display > tbody > tr.selected + tr.selected > td {
  border-top-color: #0262ef;
}

table.dataTable.cell-border > tbody > tr > th, table.dataTable.cell-border > tbody > tr > td {
  border-top: 1px solid #00000026;
  border-right: 1px solid #00000026;
}

table.dataTable.cell-border > tbody > tr > th:first-child, table.dataTable.cell-border > tbody > tr > td:first-child {
  border-left: 1px solid #00000026;
}

table.dataTable.cell-border > tbody > tr:first-child > th, table.dataTable.cell-border > tbody > tr:first-child > td {
  border-top: none;
}

table.dataTable.stripe > tbody > tr.odd > *, table.dataTable.display > tbody > tr.odd > * {
  box-shadow: inset 0 0 0 9999px #00000006;
  box-shadow: inset 0 0 0 9999px rgba(var(--dt-row-stripe), .023);
}

table.dataTable.stripe > tbody > tr.odd.selected > *, table.dataTable.display > tbody > tr.odd.selected > * {
  box-shadow: inset 0 0 0 9999px #0d6efdeb;
  box-shadow: inset 0 0 0 9999px rgba(var(--dt-row-selected), .923);
}

table.dataTable.hover > tbody > tr:hover > *, table.dataTable.display > tbody > tr:hover > * {
  box-shadow: inset 0 0 0 9999px #00000009;
  box-shadow: inset 0 0 0 9999px rgba(var(--dt-row-hover), .035);
}

table.dataTable.hover > tbody > tr.selected:hover > *, table.dataTable.display > tbody > tr.selected:hover > * {
  box-shadow: inset 0 0 0 9999px #0d6efd !important;
  box-shadow: inset 0 0 0 9999px rgba(var(--dt-row-selected), 1) !important;
}

table.dataTable.order-column > tbody tr > .sorting_1, table.dataTable.order-column > tbody tr > .sorting_2, table.dataTable.order-column > tbody tr > .sorting_3, table.dataTable.display > tbody tr > .sorting_1, table.dataTable.display > tbody tr > .sorting_2, table.dataTable.display > tbody tr > .sorting_3 {
  box-shadow: inset 0 0 0 9999px #00000005;
  box-shadow: inset 0 0 0 9999px rgba(var(--dt-column-ordering), .019);
}

table.dataTable.order-column > tbody tr.selected > .sorting_1, table.dataTable.order-column > tbody tr.selected > .sorting_2, table.dataTable.order-column > tbody tr.selected > .sorting_3, table.dataTable.display > tbody tr.selected > .sorting_1, table.dataTable.display > tbody tr.selected > .sorting_2, table.dataTable.display > tbody tr.selected > .sorting_3 {
  box-shadow: inset 0 0 0 9999px #0d6efdea;
  box-shadow: inset 0 0 0 9999px rgba(var(--dt-row-selected), .919);
}

table.dataTable.display > tbody > tr.odd > .sorting_1, table.dataTable.order-column.stripe > tbody > tr.odd > .sorting_1 {
  box-shadow: inset 0 0 0 9999px #0000000e;
  box-shadow: inset 0 0 0 9999px rgba(var(--dt-column-ordering), .054);
}

table.dataTable.display > tbody > tr.odd > .sorting_2, table.dataTable.order-column.stripe > tbody > tr.odd > .sorting_2 {
  box-shadow: inset 0 0 0 9999px #0000000c;
  box-shadow: inset 0 0 0 9999px rgba(var(--dt-column-ordering), .047);
}

table.dataTable.display > tbody > tr.odd > .sorting_3, table.dataTable.order-column.stripe > tbody > tr.odd > .sorting_3 {
  box-shadow: inset 0 0 0 9999px #0000000a;
  box-shadow: inset 0 0 0 9999px rgba(var(--dt-column-ordering), .039);
}

table.dataTable.display > tbody > tr.odd.selected > .sorting_1, table.dataTable.order-column.stripe > tbody > tr.odd.selected > .sorting_1 {
  box-shadow: inset 0 0 0 9999px #0d6efdf3;
  box-shadow: inset 0 0 0 9999px rgba(var(--dt-row-selected), .954);
}

table.dataTable.display > tbody > tr.odd.selected > .sorting_2, table.dataTable.order-column.stripe > tbody > tr.odd.selected > .sorting_2 {
  box-shadow: inset 0 0 0 9999px #0d6efdf1;
  box-shadow: inset 0 0 0 9999px rgba(var(--dt-row-selected), .947);
}

table.dataTable.display > tbody > tr.odd.selected > .sorting_3, table.dataTable.order-column.stripe > tbody > tr.odd.selected > .sorting_3 {
  box-shadow: inset 0 0 0 9999px #0d6efdef;
  box-shadow: inset 0 0 0 9999px rgba(var(--dt-row-selected), .939);
}

table.dataTable.display > tbody > tr.even > .sorting_1, table.dataTable.order-column.stripe > tbody > tr.even > .sorting_1 {
  box-shadow: inset 0 0 0 9999px #00000005;
  box-shadow: inset 0 0 0 9999px rgba(var(--dt-row-selected), .019);
}

table.dataTable.display > tbody > tr.even > .sorting_2, table.dataTable.order-column.stripe > tbody > tr.even > .sorting_2 {
  box-shadow: inset 0 0 0 9999px #00000003;
  box-shadow: inset 0 0 0 9999px rgba(var(--dt-row-selected), .011);
}

table.dataTable.display > tbody > tr.even > .sorting_3, table.dataTable.order-column.stripe > tbody > tr.even > .sorting_3 {
  box-shadow: inset 0 0 0 9999px #00000001;
  box-shadow: inset 0 0 0 9999px rgba(var(--dt-row-selected), .003);
}

table.dataTable.display > tbody > tr.even.selected > .sorting_1, table.dataTable.order-column.stripe > tbody > tr.even.selected > .sorting_1 {
  box-shadow: inset 0 0 0 9999px #0d6efdea;
  box-shadow: inset 0 0 0 9999px rgba(var(--dt-row-selected), .919);
}

table.dataTable.display > tbody > tr.even.selected > .sorting_2, table.dataTable.order-column.stripe > tbody > tr.even.selected > .sorting_2 {
  box-shadow: inset 0 0 0 9999px #0d6efde8;
  box-shadow: inset 0 0 0 9999px rgba(var(--dt-row-selected), .911);
}

table.dataTable.display > tbody > tr.even.selected > .sorting_3, table.dataTable.order-column.stripe > tbody > tr.even.selected > .sorting_3 {
  box-shadow: inset 0 0 0 9999px #0d6efde6;
  box-shadow: inset 0 0 0 9999px rgba(var(--dt-row-selected), .903);
}

table.dataTable.display tbody tr:hover > .sorting_1, table.dataTable.order-column.hover tbody tr:hover > .sorting_1 {
  box-shadow: inset 0 0 0 9999px #00000015;
  box-shadow: inset 0 0 0 9999px rgba(var(--dt-row-hover), .082);
}

table.dataTable.display tbody tr:hover > .sorting_2, table.dataTable.order-column.hover tbody tr:hover > .sorting_2 {
  box-shadow: inset 0 0 0 9999px #00000013;
  box-shadow: inset 0 0 0 9999px rgba(var(--dt-row-hover), .074);
}

table.dataTable.display tbody tr:hover > .sorting_3, table.dataTable.order-column.hover tbody tr:hover > .sorting_3 {
  box-shadow: inset 0 0 0 9999px #00000010;
  box-shadow: inset 0 0 0 9999px rgba(var(--dt-row-hover), .062);
}

table.dataTable.display tbody tr:hover.selected > .sorting_1, table.dataTable.order-column.hover tbody tr:hover.selected > .sorting_1 {
  box-shadow: inset 0 0 0 9999px #0d6efdfa;
  box-shadow: inset 0 0 0 9999px rgba(var(--dt-row-selected), .982);
}

table.dataTable.display tbody tr:hover.selected > .sorting_2, table.dataTable.order-column.hover tbody tr:hover.selected > .sorting_2 {
  box-shadow: inset 0 0 0 9999px #0d6efdf8;
  box-shadow: inset 0 0 0 9999px rgba(var(--dt-row-selected), .974);
}

table.dataTable.display tbody tr:hover.selected > .sorting_3, table.dataTable.order-column.hover tbody tr:hover.selected > .sorting_3 {
  box-shadow: inset 0 0 0 9999px #0d6efdf5;
  box-shadow: inset 0 0 0 9999px rgba(var(--dt-row-selected), .962);
}

table.dataTable.no-footer {
  border-bottom: 1px solid #0000004d;
}

table.dataTable.compact thead th, table.dataTable.compact thead td, table.dataTable.compact tfoot th, table.dataTable.compact tfoot td, table.dataTable.compact tbody th, table.dataTable.compact tbody td {
  padding: 4px;
}

table.dataTable th, table.dataTable td {
  box-sizing: content-box;
}

.dataTables_wrapper {
  clear: both;
  position: relative;
}

.dataTables_wrapper .dataTables_length {
  float: left;
}

.dataTables_wrapper .dataTables_length select {
  color: inherit;
  background-color: #0000;
  border: 1px solid #aaa;
  border-radius: 3px;
  padding: 4px;
}

.dataTables_wrapper .dataTables_filter {
  float: right;
  text-align: right;
}

.dataTables_wrapper .dataTables_filter input {
  color: inherit;
  background-color: #0000;
  border: 1px solid #aaa;
  border-radius: 3px;
  margin-left: 3px;
  padding: 5px;
}

.dataTables_wrapper .dataTables_info {
  clear: both;
  float: left;
  padding-top: .755em;
}

.dataTables_wrapper .dataTables_paginate {
  float: right;
  text-align: right;
  padding-top: .25em;
}

.dataTables_wrapper .dataTables_paginate .paginate_button {
  box-sizing: border-box;
  text-align: center;
  cursor: pointer;
  background: none;
  border: 1px solid #0000;
  border-radius: 2px;
  min-width: 1.5em;
  margin-left: 2px;
  padding: .5em 1em;
  display: inline-block;
  color: inherit !important;
  text-decoration: none !important;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.current, .dataTables_wrapper .dataTables_paginate .paginate_button.current:hover {
  background: -moz-linear-gradient(top, #e6e6e60d 0%, #0000000d 100%);
  background: -ms-linear-gradient(top, #e6e6e60d 0%, #0000000d 100%);
  background: linear-gradient(#e6e6e60d 0%, #0000000d 100%);
  border: 1px solid #0000004d;
  color: inherit !important;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.disabled, .dataTables_wrapper .dataTables_paginate .paginate_button.disabled:hover, .dataTables_wrapper .dataTables_paginate .paginate_button.disabled:active {
  cursor: default;
  box-shadow: none;
  background: none;
  border: 1px solid #0000;
  color: #666 !important;
}

.dataTables_wrapper .dataTables_paginate .paginate_button:hover {
  background: -moz-linear-gradient(top, #585858 0%, #111 100%);
  background: -ms-linear-gradient(top, #585858 0%, #111 100%);
  background: linear-gradient(#585858 0%, #111 100%);
  border: 1px solid #111;
  color: #fff !important;
}

.dataTables_wrapper .dataTables_paginate .paginate_button:active {
  background: -moz-linear-gradient(top, #2b2b2b 0%, #0c0c0c 100%);
  background: -ms-linear-gradient(top, #2b2b2b 0%, #0c0c0c 100%);
  background: linear-gradient(#2b2b2b 0%, #0c0c0c 100%);
  outline: none;
  box-shadow: inset 0 0 3px #111;
}

.dataTables_wrapper .dataTables_paginate .ellipsis {
  padding: 0 1em;
}

.dataTables_wrapper .dataTables_length, .dataTables_wrapper .dataTables_filter, .dataTables_wrapper .dataTables_info, .dataTables_wrapper .dataTables_processing, .dataTables_wrapper .dataTables_paginate {
  color: inherit;
}

.dataTables_wrapper .dataTables_scroll {
  clear: both;
}

.dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody {
  -webkit-overflow-scrolling: touch;
}

.dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody > table > thead > tr > th, .dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody > table > thead > tr > td, .dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody > table > tbody > tr > th, .dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody > table > tbody > tr > td {
  vertical-align: middle;
}

.dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody > table > thead > tr > th > div.dataTables_sizing, .dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody > table > thead > tr > td > div.dataTables_sizing, .dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody > table > tbody > tr > th > div.dataTables_sizing, .dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody > table > tbody > tr > td > div.dataTables_sizing {
  height: 0;
  overflow: hidden;
  margin: 0 !important;
  padding: 0 !important;
}

.dataTables_wrapper.no-footer .dataTables_scrollBody {
  border-bottom: 1px solid #0000004d;
}

.dataTables_wrapper.no-footer div.dataTables_scrollHead table.dataTable, .dataTables_wrapper.no-footer div.dataTables_scrollBody > table {
  border-bottom: none;
}

.dataTables_wrapper:after {
  visibility: hidden;
  content: "";
  clear: both;
  height: 0;
  display: block;
}

@media screen and (width <= 767px) {
  .dataTables_wrapper .dataTables_info, .dataTables_wrapper .dataTables_paginate {
    float: none;
    text-align: center;
  }

  .dataTables_wrapper .dataTables_paginate {
    margin-top: .5em;
  }
}

@media screen and (width <= 640px) {
  .dataTables_wrapper .dataTables_length, .dataTables_wrapper .dataTables_filter {
    float: none;
    text-align: center;
  }

  .dataTables_wrapper .dataTables_filter {
    margin-top: .5em;
  }
}

html.dark {
  --dt-row-hover: 255, 255, 255;
  --dt-row-stripe: 255, 255, 255;
  --dt-column-ordering: 255, 255, 255;
}

html.dark table.dataTable > thead > tr > th, html.dark table.dataTable > thead > tr > td {
  border-bottom: 1px solid #595b5e;
}

html.dark table.dataTable > thead > tr > th:active, html.dark table.dataTable > thead > tr > td:active {
  outline: none;
}

html.dark table.dataTable > tfoot > tr > th, html.dark table.dataTable > tfoot > tr > td {
  border-top: 1px solid #595b5e;
}

html.dark table.dataTable.row-border > tbody > tr > th, html.dark table.dataTable.row-border > tbody > tr > td, html.dark table.dataTable.display > tbody > tr > th, html.dark table.dataTable.display > tbody > tr > td {
  border-top: 1px solid #404346;
}

html.dark table.dataTable.row-border > tbody > tr.selected + tr.selected > td, html.dark table.dataTable.display > tbody > tr.selected + tr.selected > td {
  border-top-color: #0257d5;
}

html.dark table.dataTable.cell-border > tbody > tr > th, html.dark table.dataTable.cell-border > tbody > tr > td {
  border-top: 1px solid #404346;
  border-right: 1px solid #404346;
}

html.dark table.dataTable.cell-border > tbody > tr > th:first-child, html.dark table.dataTable.cell-border > tbody > tr > td:first-child {
  border-left: 1px solid #404346;
}

html.dark .dataTables_wrapper .dataTables_filter input, html.dark .dataTables_wrapper .dataTables_length select {
  background-color: var(--dt-html-background);
  border: 1px solid #fff3;
}

html.dark .dataTables_wrapper .dataTables_paginate .paginate_button.current, html.dark .dataTables_wrapper .dataTables_paginate .paginate_button.current:hover {
  background: #ffffff26;
  border: 1px solid #595b5e;
}

html.dark .dataTables_wrapper .dataTables_paginate .paginate_button.disabled, html.dark .dataTables_wrapper .dataTables_paginate .paginate_button.disabled:hover, html.dark .dataTables_wrapper .dataTables_paginate .paginate_button.disabled:active {
  color: #666 !important;
}

html.dark .dataTables_wrapper .dataTables_paginate .paginate_button:hover {
  background: #353535;
  border: 1px solid #353535;
}

html.dark .dataTables_wrapper .dataTables_paginate .paginate_button:active {
  background: #3a3a3a;
}

body {
  color: #363635;
  background-color: #fffeeb;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
}

#datatable {
  background-color: #fff;
}

a {
  color: #007bff;
  text-decoration: none;
}

.tag {
  color: #fff;
  border-radius: 4px;
  margin: 2px;
  padding: 2px 8px;
  display: inline-block;
}

/*# sourceMappingURL=index.1fdd7341.css.map */


@import 'npm:datatables.net-dt/css/jquery.dataTables.min.css';

body {
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    background-color: #fffeeb;
    color: #363635;
}

#datatable {
    background-color: white;
}

a {
    color: #007bff;
    text-decoration: none;
}

.tag {
    display: inline-block;
    padding: 2px 8px;
    margin: 2px;
    color: white;
    border-radius: 4px;
}
